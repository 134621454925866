<template>
  <div>
    <div
      ref="wrapper"
      class="overflow-hidden"
      :style="{
        maxHeight: expandText || forceOpen ? wrapperHeight + 'px' : maxHeight,
      }"
    >
      <slot></slot>
    </div>
    <span
      v-if="shouldShowShowMore && !forceOpen"
      class="text-nami-comi-blue select-none cursor-pointer"
      @click="expandText = !expandText"
    >
      {{ expandText ? "Show less..." : "Show more..." }}
    </span>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{ maxHeight?: string; forceOpen?: boolean }>(),
  {
    maxHeight: "120px",
  },
);

const wrapper = ref<HTMLDivElement>();
const expandText = ref(false);
const wrapperHeight = ref(wrapper.value?.scrollHeight ?? props.maxHeight);
const shouldShowShowMore = ref(true);

const updateShouldShowMore = () => {
  wrapperHeight.value = wrapper.value?.scrollHeight ?? props.maxHeight;

  shouldShowShowMore.value =
    (wrapper.value?.scrollHeight ?? 0) > (wrapper.value?.clientHeight ?? 0) ||
    (expandText.value &&
      (wrapper.value?.clientHeight ?? 0) >
        parseInt(props.maxHeight.replace("px", "")));
};

const postSizeObserver = ref<ResizeObserver | undefined>();
updateShouldShowMore();

onMounted(() => {
  postSizeObserver.value = new ResizeObserver(updateShouldShowMore);
  if (wrapper.value) postSizeObserver.value.observe(wrapper.value);
  updateShouldShowMore();
});

onBeforeUnmount(() => {
  postSizeObserver.value?.disconnect();
});
</script>
